'use strict';
var _ = require("./../../bower_components/lodash/dist/lodash.compat.js");
var S = require("./../../bower_components/stringjs/lib/string.min.js");

angular.module('webApp').directive('parallax', ['$window', function ($window) {
        return {
            restrict: 'A',
            scope: {
                parallaxRatio: '@',
                parallaxVerticalOffset: '@',
                parallaxHorizontalOffset: '@',
            },
            link: function ($scope, elem, $attrs) {
                var setPosition = function () {
                    // horizontal positioning
                    elem.css('left', $scope.parallaxHorizontalOffset + "px");

                    var calcValY = $window.pageYOffset * ($scope.parallaxRatio ? $scope.parallaxRatio : 1.1 );
                    if (calcValY <= $window.innerHeight) {
                        var topVal = (calcValY < $scope.parallaxVerticalOffset ? $scope.parallaxVerticalOffset : calcValY);
                        elem.css('top', topVal + "px");
                    }
                };

                setPosition();

                angular.element($window).bind("scroll", setPosition);
                angular.element($window).bind("touchmove", setPosition);
            }  // link function
        };
    }]).directive('parallaxBackground', ['$window', function ($window) {
        return {
            restrict: 'AC',
            transclude: true,
            template: '<div ng-transclude></div>',
            scope: {
                parallaxRatio: '@',
            },
            link: function ($scope, elem, attrs) {

                //if directive is declared via class,
                //evaluate the object provided by the declaration
                //and shove it into the attrs var so that it
                // behaves like the other two declaration types.
                if (_.isString(attrs.parallaxBackground)) {
                    var directiveAtts = $scope.$eval(attrs.parallaxBackground);
                    _.merge(attrs, directiveAtts);
                }

                var setPosition = function () {
                    var calcValY = (elem.prop('offsetTop') - $window.pageYOffset) * ($scope.parallaxRatio ? $scope.parallaxRatio : 1.1 );
                    // horizontal positioning
                    elem.css('background-position', "50% " + calcValY + "px");
                };

                // set our initial position - fixes webkit background render bug
                angular.element($window).bind('load', function (e) {
                    setPosition();
                    $scope.$apply();
                });

                angular.element($window).bind("scroll", setPosition);
                angular.element($window).bind("touchmove", setPosition);
            }  // link function
        };
    }]);
