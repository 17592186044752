'use strict';
var _ = require("./../../bower_components/lodash/dist/lodash.compat.js");
var S = require("./../../bower_components/stringjs/lib/string.min.js");
var $ = window.jQuery;
var _animations = {};

angular.module('webApp')
    .directive('ceresEntranceAnimationType', ['$log', '$timeout', 'applyAnimation', function ($log, $timeout, applyAnimation) {
        return {
            restrict: 'A',
            link: function postLink(scope, element, attrs) {
                element.css('opacity', 0);

                //add a collection that tracks the attrs of
                //the different animated elements so they
                //are properly parmeterized on the triggerAnimation
                //actions.
                var unqid = _.uniqueId('animation-');
                element.attr('data-animation-id', unqid);
                attrs.hasAnimated = false;
                _animations[unqid] = attrs;

                scope.triggerInviewAnimation = function (inview, inviewpart, event) {
                    if (inview) {
                        var inviewElement = $(event.inViewTarget);
                        attrs = _animations[inviewElement.attr('data-animation-id')];
                        $log.log("Inview Animation");
                        if (!attrs.animatedIn) {
                            var animation = attrs.ceresEntranceAnimationType;
                            var delay = attrs.entranceAnimationDelay ? attrs.entranceAnimationDelay : 0;
                            var duration = attrs.entranceAnimationDuration ? attrs.entranceAnimationDuration : 0;

                            var timeout = applyAnimation.doAnimation(inviewElement, animation,
                                delay, duration);

                            applyAnimation.clearAnimation(inviewElement, animation, timeout, function () {
                                inviewElement.css('opacity', 1);
                            });
                            attrs.animatedIn = true;
                        }
                    }
                };
            }
        };
    }]);
