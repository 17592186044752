'use strict';

// home module
angular.module('ceresIconBoxCtrlModule', []).controller('ceresIconBoxCtrl', [
    '$scope',
    '$window',
    '$element',
    function ($scope, $window, $element) {

        // HELPERS
        var getData = function () {
            var ceres_uid = $element.attr("id");
            return $window.ceresData[ceres_uid];
        };
        var data = getData();

        // This routes to ng-style
        // $scope.myStyle = { ... }
    }
]);
