'use strict';
var _ = require("./../../bower_components/lodash/dist/lodash.compat.js");
var S = require("./../../bower_components/stringjs/lib/string.min.js");

angular.module('webApp')
    .directive('bsCarouselSlide', function () {
        return {
            template: '<div ng-transclude class="item"></div>',
            restrict: 'E',
            transclude: true,
            replace: true,
            link: function postLink(scope, element, attrs) {
            }
        };
    });
