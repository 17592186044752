'use strict';
var _ = require("./../../bower_components/lodash/dist/lodash.compat.js");
var $ = window.jQuery;

angular.module('webApp')
    .directive('bsCarousel', function () {
        return {
            template: '<div id="{{ elementId }}" class="carousel slide" data-ride="carousel" >' +
            '<ol class="carousel-indicators"><li ng-repeat="slides in $slides" data-target="#{{ elementId }}" data-slide-to="{{ $index }}" class="active"></li></ol>' +
            '<div ng-transclude class="carousel-inner" role="listbox"></div>' +
            '<a class="left carousel-control" data-target="#{{ elementId }}" role="button" data-slide="prev"> <i class="fa fa-chevron-left"></i><span class="sr-only">Previous</span></a>' +
            '<a class="right carousel-control" data-target="#{{ elementId }}" role="button" data-slide="next"><i class="fa fa-chevron-right"></i><span class="sr-only">Next</span></a>',
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: {},
            link: function postLink(scope, element, attrs) {
                function guid() {
                    function _p8(s) {
                        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
                        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
                    }

                    return _p8() + _p8(true) + _p8(true) + _p8();
                }

                scope.elementId = guid();
                console.log("Element ID: " + scope.elementId);
                var $el = $(element);
                console.log($el);
                $el.css('background', 'black');

                scope.$slides = $el.find('.carousel-inner .item');

                if($('.active', scope.$slides).length == 0 && scope.$slides.length > 0) {
                    scope.$slides.first().addClass('active');
                }
            }
        };
    });
