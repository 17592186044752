'use strict';

// home module
angular.module('ceresGoogleMapMarkerCtrlModule', []).controller('ceresGoogleMapMarkerCtrl', [
    '$scope',
    '$window',
    '$log',
    '$element',
    function ($scope, $window, $log, $element) {
        $scope.showWindow = false;
        // HELPERS
        var ceres_uid = $element.attr("id");
        var getData = function () {

            return $window.ceresData[ceres_uid];
        };
        var data = getData();

        $scope.marker = {
            id: ceres_uid,
            coords: {
                latitude: data.location_lat,
                longitude: data.location_long
            }
        };

        if(data.icon){
            $scope.marker.icon = data.icon;
        }

        $scope.clicked = function () {
            if (data.content) {
                $scope.showWindow = !$scope.showWindow();
            }
        };

    }
]);
