'use strict';
var $ = window.jQuery;
var _ = require("./../../bower_components/lodash/dist/lodash.compat.js");
var S = require("./../../bower_components/stringjs/lib/string.min.js");

angular.module('webApp').constant("ceresGradients",
    {
        gradients: [
            {
                "name":"Emerald Water",
                "color1":"#348F50",
                "color2":"#56B4D3"
            },
            {
                "name":"Lemon Twist",
                "color1":"#3CA55C",
                "color2":"#B5AC49"
            },
            {
                "name":"Horizon",
                "color1":"#003973",
                "color2":"#E5E5BE"
            },
            {
                "name":"Rose Water",
                "color1":"#E55D87",
                "color2":"#5FC3E4"
            },
            {
                "name":"Frozen",
                "color1":"#403B4A",
                "color2":"#E7E9BB"
            },
            {
                "name":"Mango Pulp",
                "color1":"#F09819",
                "color2":"#EDDE5D"
            },
            {
                "name":"Bloody Mary",
                "color1":"#FF512F",
                "color2":"#DD2476"
            },
            {
                "name":"Aubergine",
                "color1":"#AA076B",
                "color2":"#61045F"
            },
            {
                "name":"Aqua Marine",
                "color1":"#1A2980",
                "color2":"#26D0CE"
            },
            {
                "name":"Sunrise",
                "color1":"#FF512F",
                "color2":"#F09819"
            },
            {
                "name":"Purple Paradise",
                "color1":"#1D2B64",
                "color2":"#F8CDDA"
            },
            {
                "name":"Sea Weed",
                "color1":"#4CB8C4",
                "color2":"#3CD3AD"
            },
            {
                "name":"Pinky",
                "color1":"#DD5E89",
                "color2":"#F7BB97"
            },
            {
                "name":"Cherry",
                "color1":"#EB3349",
                "color2":"#F45C43"
            },
            {
                "name":"Mojito",
                "color1":"#1D976C",
                "color2":"#93F9B9"
            },
            {
                "name":"Juicy Orange",
                "color1":"#FF8008",
                "color2":"#FFC837"
            },
            {
                "name":"Mirage",
                "color1":"#16222A",
                "color2":"#3A6073"
            },
            {
                "name":"Steel Gray",
                "color1":"#1F1C2C",
                "color2":"#928DAB"
            },
            {
                "name":"Kashmir",
                "color1":"#614385",
                "color2":"#516395"
            },
            {
                "name":"Electric Violet",
                "color1":"#4776E6",
                "color2":"#8E54E9"
            },
            {
                "name":"Venice Blue",
                "color1":"#085078",
                "color2":"#85D8CE"
            },
            {
                "name":"Bora Bora",
                "color1":"#2BC0E4",
                "color2":"#EAECC6"
            },
            {
                "name":"Moss",
                "color1":"#134E5E",
                "color2":"#71B280"
            },
            {
                "name":"Shroom Haze",
                "color1":"#5C258D",
                "color2":"#4389A2"
            },
            {
                "name":"Mystic",
                "color1":"#757F9A",
                "color2":"#D7DDE8"
            },
            {
                "name":"Midnight City",
                "color1":"#232526",
                "color2":"#414345"
            },
            {
                "name":"Sea Blizz",
                "color1":"#1CD8D2",
                "color2":"#93EDC7"
            },
            {
                "name":"Opa",
                "color1":"#3D7EAA",
                "color2":"#FFE47A"
            },
            {
                "name":"Titanium",
                "color1":"#283048",
                "color2":"#859398"
            },
            {
                "name":"Mantle",
                "color1":"#24C6DC",
                "color2":"#514A9D"
            },
            {
                "name":"Dracula",
                "color1":"#DC2424",
                "color2":"#4A569D"
            },
            {
                "name":"Peach",
                "color1":"#ED4264",
                "color2":"#FFEDBC"
            },
            {
                "name":"Moonrise",
                "color1":"#DAE2F8",
                "color2":"#D6A4A4"
            },
            {
                "name":"Clouds",
                "color1":"#ECE9E6",
                "color2":"#FFFFFF"
            },
            {
                "name":"Stellar",
                "color1":"#7474BF",
                "color2":"#348AC7"
            },
            {
                "name":"Bourbon",
                "color1":"#EC6F66",
                "color2":"#F3A183"
            },
            {
                "name":"Calm Darya",
                "color1":"#5f2c82",
                "color2":"#49a09d"
            },
            {
                "name":"Influenza",
                "color1":"#C04848",
                "color2":"#480048"
            },
            {
                "name":"Shrimpy",
                "color1":"#e43a15",
                "color2":"#e65245"
            },
            {
                "name":"Army",
                "color1":"#414d0b",
                "color2":"#727a17"
            },
            {
                "name":"Miaka",
                "color1":"#FC354C",
                "color2":"#0ABFBC"
            },
            {
                "name":"Pinot Noir",
                "color1":"#4b6cb7",
                "color2":"#182848"
            },
            {
                "name":"Day Tripper",
                "color1":"#f857a6",
                "color2":"#ff5858"
            },
            {
                "name":"Namn",
                "color1":"#a73737",
                "color2":"#7a2828"
            },
            {
                "name":"Blurry Beach",
                "color1":"#d53369",
                "color2":"#cbad6d"
            },
            {
                "name":"Vasily",
                "color1":"#e9d362",
                "color2":"#333333"
            },
            {
                "name":"A Lost Memory",
                "color1":"#DE6262",
                "color2":"#FFB88C"
            },
            {
                "name":"Petrichor",
                "color1":"#666600",
                "color2":"#999966"
            },
            {
                "name":"Jonquil",
                "color1":"#FFEEEE",
                "color2":"#DDEFBB"
            },
            {
                "name":"Sirius Tamed",
                "color1":"#EFEFBB",
                "color2":"#D4D3DD"
            },
            {
                "name":"Kyoto",
                "color1":"#c21500",
                "color2":"#ffc500"
            },
            {
                "name":"Misty Meadow",
                "color1":"#215f00",
                "color2":"#e4e4d9"
            },
            {
                "name":"Aqualicious",
                "color1":"#50C9C3",
                "color2":"#96DEDA"
            },
            {
                "name":"Moor",
                "color1":"#616161",
                "color2":"#9bc5c3"
            },
            {
                "name":"Almost",
                "color1":"#ddd6f3",
                "color2":"#faaca8"
            },
            {
                "name":"Forever Lost",
                "color1":"#5D4157",
                "color2":"#A8CABA"
            },
            {
                "name":"Winter",
                "color1":"#E6DADA",
                "color2":"#274046"
            },
            {
                "name":"Autumn",
                "color1":"#DAD299",
                "color2":"#B0DAB9"
            },
            {
                "name":"Candy",
                "color1":"#D3959B",
                "color2":"#BFE6BA"
            },
            {
                "name":"Reef",
                "color1":"#00d2ff",
                "color2":"#3a7bd5"
            },
            {
                "name":"The Strain",
                "color1":"#870000",
                "color2":"#190A05"
            },
            {
                "name":"Dirty Fog",
                "color1":"#B993D6",
                "color2":"#8CA6DB"
            },
            {
                "name":"Earthly",
                "color1":"#649173",
                "color2":"#DBD5A4"
            },
            {
                "name":"Virgin",
                "color1":"#C9FFBF",
                "color2":"#FFAFBD"
            },
            {
                "name":"Ash",
                "color1":"#606c88",
                "color2":"#3f4c6b"
            },
            {
                "name":"Shadow Night",
                "color1":"#000000",
                "color2":"#53346D"
            },
            {
                "name":"Cherryblossoms",
                "color1":"#FBD3E9",
                "color2":"#BB377D"
            },
            {
                "name":"Parklife",
                "color1":"#ADD100",
                "color2":"#7B920A"
            },
            {
                "name":"Dance To Forget",
                "color1":"#FF4E50",
                "color2":"#F9D423"
            },
            {
                "name":"Starfall",
                "color1":"#F0C27B",
                "color2":"#4B1248"
            },
            {
                "name":"Red Mist",
                "color1":"#000000",
                "color2":"#e74c3c"
            },
            {
                "name":"Teal Love",
                "color1":"#AAFFA9",
                "color2":"#11FFBD"
            },
            {
                "name":"Neon Life",
                "color1":"#B3FFAB",
                "color2":"#12FFF7"
            },
            {
                "name":"Man of Steel",
                "color1":"#780206",
                "color2":"#061161"
            },
            {
                "name":"Amethyst",
                "color1":"#9D50BB",
                "color2":"#6E48AA"
            },
            {
                "name":"Cheer Up Emo Kid",
                "color1":"#556270",
                "color2":"#FF6B6B"
            },
            {
                "name":"Shore",
                "color1":"#70e1f5",
                "color2":"#ffd194"
            },
            {
                "name":"Facebook Messenger",
                "color1":"#00c6ff",
                "color2":"#0072ff"
            },
            {
                "name":"SoundCloud",
                "color1":"#fe8c00",
                "color2":"#f83600"
            },
            {
                "name":"Behongo",
                "color1":"#52c234",
                "color2":"#061700"
            },
            {
                "name":"ServQuick",
                "color1":"#485563",
                "color2":"#29323c"
            },
            {
                "name":"Friday",
                "color1":"#83a4d4",
                "color2":"#b6fbff"
            },
            {
                "name":"Martini",
                "color1":"#FDFC47",
                "color2":"#24FE41"
            },
            {
                "name":"Metallic Toad",
                "color1":"#abbaab",
                "color2":"#ffffff"
            },
            {
                "name":"Between The Clouds",
                "color1":"#73C8A9",
                "color2":"#373B44"
            },
            {
                "name":"Crazy Orange I",
                "color1":"#D38312",
                "color2":"#A83279"
            },
            {
                "name":"Hersheys",
                "color1":"#1e130c",
                "color2":"#9a8478"
            },
            {
                "name":"Talking To Mice Elf",
                "color1":"#948E99",
                "color2":"#2E1437"
            },
            {
                "name":"Purple Bliss",
                "color1":"#360033",
                "color2":"#0b8793"
            },
            {
                "name":"Predawn",
                "color1":"#FFA17F",
                "color2":"#00223E"
            },
            {
                "name":"Endless River",
                "color1":"#43cea2",
                "color2":"#185a9d"
            },
            {
                "name":"Pastel Orange at the Sun",
                "color1":"#ffb347",
                "color2":"#ffcc33"
            },
            {
                "name": "Twitch",
                "color1": "#6441A5",
                "color2": "#2a0845"
            },
            {
                "name": "Instagram",
                "color1": "#517fa4",
                "color2": "#243949"
            },
            {
                "name": "Flickr",
                "color1": "#ff0084",
                "color2": "#33001b"
            },
            {
                "name": "Vine",
                "color1": "#00bf8f",
                "color2": "#001510"
            }
        ]})
    .directive('ceresGradientBg', [
        '$log', '$interpolate', 'ceresGradients',
        function ($log, $interpolate, ceresGradients) {

            function link(scope, element, attrs) {

                //if directive is declared via class,
                //evaluate the object provided by the declaration
                //and shove it into the attrs var so that it
                // behaves like the other two declaration types.
                if (_.isString(attrs.ceresGradientBg)) {
                    var directiveAtts = scope.$eval(attrs.ceresGradientBg);
                    _.merge(attrs, directiveAtts);
                }

                var gradientsMap = _.indexBy(ceresGradients.gradients, function (grad) {
                    return S(grad.name).dasherize().chompLeft('-').s;
                });

                var selectedGradientStyle = attrs.gradientStyle ? attrs.gradientStyle : "emerald-water";
                var selectedGradient = gradientsMap[selectedGradientStyle];

                var $el = $(element);
                var vendors = ['webkit', 'moz', 'ms', 'o'];
                vendors = _.mapValues(vendors, function (v) {
                    return S(v).ensureLeft('-').ensureRight('-').s;
                });
                vendors = _.values(vendors);
                vendors.push('');

                var background = $interpolate("background: {{ vendor }}linear-gradient({{ degree }}deg, {{ color1 }} 10%, {{ color2 }} 90%);");
                var bkgdRules = _.mapValues(vendors, function (v) {
                    selectedGradient.vendor = v;
                    selectedGradient.degree = attrs.gradientDegree ? attrs.gradientDegree : 90;
                    return background(selectedGradient);
                });

                var bkgdString = _.reduce(bkgdRules, function (all, rule) {
                    return all + rule;
                });

                var elStyle = $el.attr("style") + bkgdString;

                $el.attr("style", elStyle);
            }

            return {
                restrict: 'AEC',
                link: link
            };
        }]);
