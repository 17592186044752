'use strict';
var _ = require("./../../bower_components/lodash/dist/lodash.compat.js");
var S = require("./../../bower_components/stringjs/lib/string.min.js");

angular.module('webApp')
    .directive('ceresClickAnimation', ['$log', '$timeout', 'applyAnimation', function ($log, $timeout, applyAnimation) {
        return {
            restrict: 'A',
            link: function postLink(scope, element, attrs) {
                var animation = attrs.ceresClickAnimation;
                var delay = attrs.ceresClickAnimationDelay;
                var duration = attrs.clickAnimationDuration;

                element.bind('click', function () {
                    var timeout = applyAnimation.doAnimation(element, animation,
                        delay, duration);

                    applyAnimation.clearAnimation(element, animation, timeout);

                });
            }
        };
    }]);
