'use strict';

angular.module('webApp')
    .directive('counter', ['$log', '$window', function ($log, $window) {
        return {
            template: '<div in-view="animate($inview)"></div>',
            restrict: 'E',
            controller: function ($scope) {

            },
            scope: {},
            link: function postLink(scope, element, attrs) {
                var _ = $window._;
                var numeral = $window.numeral;
                var TweenMax = $window.TweenMax;
                var Power2 = $window.Power2;

                //get the start & end values
                var currVal = attrs.startValue;
                var endVal = attrs.endValue;
                var prefix = attrs.prefix ? attrs.prefix : '';
                var postfix = attrs.postfix ? attrs.postfix : '';
                var format = attrs.numberFormat ? attrs.numberFormat : '0,0';

                //animation options
                var duration = attrs.animationDuration ? parseFloat(attrs.animationDuration) : 5;
                var animationDelay = attrs.animationDelay ? parseFloat(attrs.animationDelay) : 0;
                var easing = attrs.easing ? attrs.easing : 'Power2.easeInOut';
                var easingParts = easing.split('.');

                var easeObj = $window;
                _.forEach(easingParts, function (ease) {
                    easeObj = easeObj[ease];
                });
                easing = easeObj;

                //numeraljs configuration;
                var defaultLang = numeral.languageData('en');
                var oldLang = _.cloneDeep(defaultLang);


                //NOTE: We have to directly edit the DOM because the NG apply
                //isn't sufficient for animating steps.
                element.text(currVal);

                scope.getTextVal = function () {
                    return parseFloat(currVal);
                };
                scope.setTextVal = function (val) {
                    if (attrs.thousandsDelimiter) {
                        defaultLang.delimiters.thousands = attrs.thousandsDelimiter;
                    }
                    if (attrs.decimalDelimiter) {
                        defaultLang.delimiters.decimal = attrs.decimalDelimiter;
                    }

                    var currVal = parseFloat(val, 10);
                    var numStr = prefix + numeral(currVal).format(format) + postfix;
                    element.text(numStr);
                };

                var animated = false;
                scope.animate = function (inview) {
                    if (!animated && inview) {
                        TweenMax.to(scope, duration, { delay: animationDelay, setTextVal: endVal, ease: easing, onComplete: function () {
                            //reset the default numeral.
                            numeral.language('en', oldLang);
                        }});
                        animated = true;
                    }
                };
            }
        };
    }]);
