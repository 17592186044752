'use strict';

var $ = window.jQuery;
var _ = require("./../../bower_components/lodash/dist/lodash.compat.js");
var S = require("./../../bower_components/stringjs/lib/string.min.js");

angular.module('webApp')
    .directive('ceresHoverAnimation',  ['$log', '$timeout', 'applyAnimation', function ($log, $timeout, applyAnimation) {
        return {
            restrict: 'A',
            link: function postLink(scope, element, attrs) {
                var animation = attrs.ceresHoverAnimation;
                var delay = attrs.ceresHoverAnimationDelay;
                var duration = attrs.hoverAnimationDuration;

                element.bind('mouseenter', function () {
                    var timeout = applyAnimation.doAnimation(element, animation,
                        delay, duration);
                    applyAnimation.clearAnimation(element, animation, timeout);

                });

            }
        };
    }]);
