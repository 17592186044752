'use strict';

var $ = window.jQuery
var _ = require("./../../bower_components/lodash/dist/lodash.compat.js");
var S = require("./../../bower_components/stringjs/lib/string.min.js");

angular.module('webApp')
    .factory('applyAnimation', function (browserVendorStrings, $log, $timeout) {

        var setAllVendorCSS = function (element, property, value) {
            _.forEach(browserVendorStrings, function (prefix) {
                var stylePrefix = S(prefix);
                if (prefix != '') {
                    stylePrefix.ensureLeft('-').ensureRight('-');
                }
                element.css(stylePrefix.ensureRight(property).s, value);
            });
        };

        // Public API here
        return {
            doAnimation: function (element, animation, delay, duration) {
                element = $(element);
                if (!element.hasClass('animated')) {
                    if (duration !== undefined) {
                        setAllVendorCSS(element, 'animation-duration', S(duration).ensureRight('s').s);
                    }
                    if (delay !== undefined) {
                        setAllVendorCSS(element, 'animation-delay', S(delay).ensureRight('s').s);
                    }

                    element.addClass("animated " + animation);
                    $log.log("Added Animated class");
                    delay = parseFloat(delay);
                    duration = parseFloat(duration);
                    delay = !isNaN(delay) ? delay : 0;
                    duration = !isNaN(duration) ? duration : 0;
                    return (delay + duration) * 1000;
                }
            },
            clearAnimation: function (element, animation, timeout, cb) {
                timeout = timeout > 0 ? timeout : 750;
                $timeout(function () {
                    element = $(element);
                    element.removeClass("animated");
                    element.removeClass(animation);
                    $log.log("Removed Animated class");

                }, timeout);
                if (cb) {
                    cb();
                }
            }
        };
    });
