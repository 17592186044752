'use strict';

angular.module('webApp')
    .directive('ceresFlipbook', function () {
        return {
            restrict: 'A',
            link: function postLink(scope, element, attrs) {
                var $ = jQuery;

                element.addClass('bb-custom-wrapper');
                var $el = $(element);


                var config = {
                        $bookBlock: $el.find('.bb-bookblock'),
                        $navNext: $el.find('.bb-nav-next'),
                        $navPrev: $el.find('.bb-nav-prev'),
                        $navFirst: $el.find('.bb-nav-first'),
                        $navLast: $el.find('.bb-nav-last')
                    },
                    init = function () {
                        config.$bookBlock.bookblock({
                            speed: 800,
                            shadowSides: 0.8,
                            shadowFlip: 0.7
                        });
                        initEvents();
                    },
                    initEvents = function () {

                        var $slides = config.$bookBlock.children();

                        // add navigation events
                        config.$navNext.on('click touchstart', function () {
                            config.$bookBlock.bookblock('next');
                            return false;
                        });

                        config.$navPrev.on('click touchstart', function () {
                            config.$bookBlock.bookblock('prev');
                            return false;
                        });

                        config.$navFirst.on('click touchstart', function () {
                            config.$bookBlock.bookblock('first');
                            return false;
                        });

                        config.$navLast.on('click touchstart', function () {
                            config.$bookBlock.bookblock('last');
                            return false;
                        });

                        // add swipe events
                        $slides.on({
                            'swipeleft': function (event) {
                                config.$bookBlock.bookblock('next');
                                return false;
                            },
                            'swiperight': function (event) {
                                config.$bookBlock.bookblock('prev');
                                return false;
                            }
                        });
                    };
                init();

            }
        };
    })
;
