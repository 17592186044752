'use strict';
var snazzy = require('./snazzy');
var _ = require("./../../bower_components/lodash/dist/lodash.compat.js");

// home module
angular.module('ceresGoogleMapCtrlModule', []).controller('ceresGoogleMapCtrl', [
    '$scope',
    '$window',
    '$element',
    '$log',
    function ($scope, $window, $element, $log) {

        // HELPERS
        var getData = function () {
            var ceres_uid = $element.attr("id");
            return $window.ceresData[ceres_uid];
        };
        var data = getData();

        $scope.map = {
            center: {
                latitude: data.location_lat,
                longitude: data.location_long
            },
            zoom: data.map_zoom,
            draggable: data.draggable
        };

        var value =
            _.find(snazzy.styles, function (style) {
                return style.name == data.styling;
            });

        $scope.options = {};
        if (value) {
            $scope.options.styles = value.json
        }

        $scope.options.mapTypeId = $window.google.maps.MapTypeId[data.map_type];
        $scope.options.scrollwheel = !data.display_map_zoom_scroll;
        $scope.options.mapTypeControl = data.map_type_control;
        $scope.options.panControl = data.pan_control;

        $scope.options.streetViewControl = data.street_view_control;

        $scope.options.zoomControl = data.zoom_control;
        var zoomSize = data.zoom_control_size.toUpperCase();
        $scope.options.zoomControlOptions = {
            style: $window.google.maps.ZoomControlStyle[zoomSize]
        };

    }
]);
