'use strict';

// load src modules
// ADD REQUIRE STATEMENTS FOR NEW SHORTCODES HERE

// WARNING: DO NOT DELETE THE LINE BELOW -- THE SHORTCODE GENERATOR DEPENDS ON IT
// [ShortcodeNgControllerRequireStatement]
require('./ceresSeparatorStyledCtrl/ceresSeparatorStyledCtrl');
require('./ceresIconListItemCtrl/ceresIconListItemCtrl');
require('./ceresIconListCtrl/ceresIconListCtrl');
require('./ceresIconBlockCtrl/ceresIconBlockCtrl');
require('./ceresIconLinkCtrl/ceresIconLinkCtrl');
require('./ceresIconBoxCtrl/ceresIconBoxCtrl');
require('./ceresButtonOutlineCtrl/ceresButtonOutlineCtrl');
require('./ceresButtonSolidCtrl/ceresButtonSolidCtrl');
require('./ceresAnimationBlockCtrl/ceresAnimationBlockCtrl');
require('./ceresIconListItemCtrl/ceresIconListItemCtrl');
require('./ceresGoogleMapMarkerCtrl/ceresGoogleMapMarkerCtrl');
require('./ceresGoogleMapCtrl/ceresGoogleMapCtrl');

angular.module('webApp', [
    // ADD MODULE NAMES FOR NEW SHORTCODES HERE
    'ngAnimate',
    'angular-inview',
    'timer',
    'google-maps',
    // WARNING: DO NOT DELETE THE LINE BELOW -- THE SHORTCODE GENERATOR DEPENDS ON IT
    // [ShortcodeNgModuleImport]
        'ceresSeparatorStyledCtrlModule',
        'ceresIconListItemCtrlModule',
        'ceresIconListCtrlModule',
        'ceresIconBlockCtrlModule',
        'ceresIconLinkCtrlModule',
        'ceresIconBoxCtrlModule',
        'ceresButtonOutlineCtrlModule',
        'ceresButtonSolidCtrlModule',
        'ceresAnimationBlockCtrlModule',
        'ceresIconListItemCtrlModule',
    'ceresGoogleMapMarkerCtrlModule',
    'ceresGoogleMapCtrlModule'
]);

require('./services/browservendorstrings');
require('./services/applyanimation');

require('./vendors/numeral');
require('./vendors/angular-parallax/scripts/angular-parallax.js');
require('./vendors/toucheffects');

require('./directives/angular-parallax');
require('./directives/ceresclickanimation');
require('./directives/ceresentranceanimationtype');
require('./directives/cereshoveranimation');
require('./directives/ceresgradientbg');
require('./directives/ceresvideobg');
require('./directives/ceresflipbook');
require('./directives/counter');
require('./directives/bscarousel.js');
require('./directives/bscarouselslide.js');



//angular configuration
angular.element(document).ready(function () {
    /*smart works go here*/
    var $html = angular.element('html');
    // bootstrap model
    angular.bootstrap($html, ['webApp']);
});
