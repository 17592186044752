'use strict';
var $ = window.jQuery;
var _ = require("./../../bower_components/lodash/dist/lodash.compat.js");
var S = require("./../../bower_components/stringjs/lib/string.min.js");

angular.module('webApp')
    .directive('ceresVideoBg', [
        '$log', '$interpolate', '$window',
        function ($log, $interpolate, $window) {

            function link(scope, element, attrs) {

                //if directive is declared via class,
                //evaluate the object provided by the declaration
                //and shove it into the attrs var so that it
                // behaves like the other two declaration types.
                if (_.isString(attrs.ceresVideoBg)) {
                    var directiveAtts = scope.$eval(attrs.ceresVideoBg);
                    _.merge(attrs, directiveAtts);
                }

                var template =
                    '<div class="ceres-background-video">' +
                    '<video muted="muted" preload="auto" autoplay="autoplay" loop="loop" poster="{{ poster }}" class="video-js">' +
                    '<source src="{{ mp4Path }}" type="video/mp4" />' +
                    '<source src="{{ webmPath }}" type="video/webm" />' +
                    '</video>' +
                    '</div>';
                var compiledTemplate = $interpolate(template);
                var videoHtml = compiledTemplate(attrs);

                element.addClass('has-ceres-video-bg');
                element.prepend(videoHtml);

                var videoEl = element.find('.ceres-background-video > video');
                if (videoEl.count > 0) {
                    videoEl = videoEl[0];
                }
                var updateVideoSize = function () {
                    var elWidth = element.width();
                    var elHeight = element.height();

                    var vidWidth = videoEl.width();
                    var vidHeight = videoEl.height();
                    var vidAspectRatio = vidWidth / vidHeight;


                    $(videoEl).css('min-width', Math.round(Math.max(elWidth, elHeight * vidAspectRatio)));
                    $(videoEl).css('min-height', Math.round(Math.max(elHeight, elWidth / vidAspectRatio)));

                    //Grab it again because the width and height of the video have been updated
                    vidWidth = videoEl.width();
                    vidHeight = videoEl.height();
                    // Calculate the top and left offsets to center the video
                    var left = Math.round((vidWidth - elWidth) / 2) * -1;
                    var top = Math.round((vidHeight - elHeight) / 2) * -1;
                    $(videoEl).css('left', left + 'px');
                    $(videoEl).css('top', top + 'px');
                };

                $(window).load(function () {
                    _.defer(function () {
                        updateVideoSize();
                    });
                });

                $($window).on("debouncedresize", function (event) {
                    updateVideoSize();
                });
            }

            return {
                restrict: 'AEC',
                link: link
            };
        }]);
